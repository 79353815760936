@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
  @apply bg-primary rounded-full px-6 py-3 flex items-center justify-center text-white font-medium cursor-pointer hover:opacity-80;
}

.btn-secondary {
  @apply bg-[#F4F4F4] rounded-lg px-6 py-3 flex items-center justify-center text-[#343A40] font-medium cursor-pointer hover:opacity-80;
}

.hide-pdf {
  display: none;
}
